<template>
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <h5>
          <i class="fa fa-server"></i>
          {{ lang('devices') }}
          &nbsp;
          <button class="btn btn-primary btn-sm" v-b-modal.add style="border-radius: 50%; width: 30px; height: 30px; padding: 0px">
            <i class="fa fa-plus"></i>
          </button>
          &nbsp;
          <button class="btn btn-outline-secondary btn-sm" v-b-modal.search style="border-radius: 50%; width: 30px; height: 30px; padding: 0px">
            <i class="fa fa-search"></i>
          </button>
          &nbsp;
          <button class="btn btn-outline-secondary btn-sm" v-b-modal.subscribe style="border-radius: 20px; width: 90px; height: 30px; padding: 0px">
            <i class="fa fa-calendar"></i> {{ lang('subscribe') }}
          </button>
        </h5>
      </div>
      <b-modal id="search" :title="lang('search')" hide-footer>
        <div class="form-group">
          <label for="">{{ lang('search') }}</label>
          <input type="text"
            class="form-control" v-model="q" :placeholder="lang('write-here')">
        </div>
        <div class="form-group">
          <label for="">{{ lang("section") }}</label>
          <select class="form-control" v-model="section_id">
            <option value="">-- {{ lang('all') }} --</option>
            <option v-for="s in sections" :key="s._id" :value="s._id">{{ s.title }}</option>
          </select>
        </div>
        <div class="col-12 text-center g">
          <button class="btn btn-primary" @click="getDevices(); closeMeSearch()">
            <i class="fa fa-search"></i>
            {{ lang('search') }}
          </button>
        </div>
      </b-modal>
      <b-modal id="subscribe" :title="lang('subscribe')" size="lg" hide-footer>
        <div class="col-12 table-responsive" style="min-height: auto">
          <table class="table table-hover table-sm">
            <thead>
              <th>
                {{ lang('select') }}
              </th>
              <th>
                {{ lang('title') }}
              </th>
              <th>
                {{ lang('serial-number') }}
              </th>
              <th>
                {{ lang('current-exdate') }}
              </th>
              <th>
                {{ lang('callback') }}
              </th>
            </thead>
            <tbody>
              <tr v-for="device in devices" :key="device.serial_number">
                <td>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input type="checkbox" class="form-check-input" v-model="selected" :unchecked-value="false" :value="device.serial_number">
                      {{ lang('select') }}
                    </label>
                  </div>
                </td>
                <td>
                  {{ device.title }}
                </td>
                <td>
                  {{ device.serial_number }}
                </td>
                <td>
                  {{ device.subscribe.exdate }}
                </td>
                <td>
                  <div class="form-check">
                    <label class="form-check-label">
                      <input type="checkbox" class="form-check-input" :disabled="!selected.includes(device.serial_number)" v-model="callbacks" :value="device.serial_number">
                      {{ lang('select') }}
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <hr>
          <h5>
            {{ lang('cost') }}
          </h5>
          <table class="table table-hover table-sm">
            <thead>
              <th>#</th>
              <th>
                {{ lang('device-name') }}
              </th>
              <th>
                {{ lang('serial-number') }}
              </th>
              <th>
                {{ lang('cost') }}
              </th>
              <th>
                {{ lang('callback-cost') }}
              </th>
              <th>
                {{ lang('total') }}
              </th>
            </thead>
            <tbody>
              <tr v-for="(s,i) in selected" :key="s">
                <td>{{ i+1 }}</td>
                <td>
                  {{ devices_obj[s].title }}
                </td>
                <td>
                  {{ devices_obj[s].serial_number }}
                </td>
                <td>
                  400 {{ lang('sa') }}
                </td>
                <td>
                  {{ callbacks.includes(s) ? '100 ' + lang("sa") : '--' }}
                </td>
                <td>
                  {{ callbacks.includes(s) ? '500 ' + lang("sa") : '400' }}
                </td>
              </tr>
              <tr class="bg-light-primary" v-if="total() > 0">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{ lang('total') }}</td>
                <td>{{ total() }} {{ lang('sa') }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12 text-center g">
          <button class="btn btn-primary" @click="subscribeNow()">
            <i class="fa fa-check"></i>
            {{ lang('subscribe-for-selected') }}
          </button>
        </div>
      </b-modal>
      <div class="card-footer">
        <div class="col-12 table-responsive">
          <table class="table table-hover table-sm">
            <thead>
              <th>
                {{ lang("title") }}
              </th>
              <th>
                {{ lang("serial-number") }}
              </th>
              <th>
                {{ lang("pending_records") }}
              </th>
              <th>
                {{ lang("exdate") }}
              </th>
              <th>
                Callback
              </th>
              <th>
                {{ lang("demo") }}
              </th>
              <th>
                {{ lang("last_active") }}
              </th>
              <th>
                {{ lang("options") }}
              </th>
            </thead>
            <tbody>
              <template v-for="(device, i) in devices">
                <tr v-if="i < viewlimit" :key="device._id" :class="device.last_active == 'connected-now' ? 'bg-light-success' : (device.last_active == 'not-connected-yet' ? 'bg-light-warning' : 'bg-light-danger')">
                  <td>
                    {{ device.title }}
                  </td>
                  <td>
                    {{ device.serial_number }}
                  </td>
                  <td>
                    <span v-if="device.pending_records == 0">0</span>
                    <span v-if="device.pending_records > 0" class="text-danger">
                        <img :src="require('@/assets/images/loading.svg')" style="width: 16px;" alt="">
                        {{ device.pending_records }}
                        <u href="#" @click="deleteOrders(device.serial_number)"><i class="fa fa-times"></i></u>
                    </span>
                  </td>
                  <td>
                    {{ device.subscribe.exdate }}
                  </td>
                  <td>
                    {{ device.subscribe.callback ? lang("yes") : lang("no") }}
                  </td>
                  <td>
                    {{ device.subscribe.demo ? lang("yes") : lang("no") }}
                  </td>
                  <td>
                    {{ lang(device.last_active) }}
                  </td>
                  <td>
                    <button class="btn btn-sm btn-primary" v-b-modal.options @click="current = device">
                        <i class="fa fa-cogs"></i>
                        {{ lang('options') }}
                    </button>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div class="col-12 text-center g" v-if="devices.length >= viewlimit">
          <button class="btn btn-primary btn-sm" @click="viewlimit = viewlimit + 50">
            <i class="fa fa-arrow-down"></i>
            {{ lang("load-more") }}
          </button>
        </div>
      </div>
    </div>
    <b-modal id="add" :title="lang('add-device')" hide-footer>
      <div class="alert alert-warning text-center text-dark g">
        <span class="text-dark">{{ lang('add-device-note') }}</span>
      </div>
      <div class="form-group">
        <h5 for="">{{ lang('title') }}</h5>
        <input type="text"
          class="form-control" :placeholder="lang('write-here')" v-model="create.title">
      </div>
      <div class="form-group">
        <h5 for="">{{ lang('serial-number') }}</h5>
        <input type="text"
          class="form-control" :placeholder="lang('write-here')" v-model="create.serial_number">
      </div>
      <div class="form-group">
        <label for="">{{ lang("timezone") }}</label>
        <select class="form-control" v-model="create.timezone">
          <option value="3">{{ lang('Asia/Riyadh') }}</option>
          <option value="2">{{ lang('Africa/Cairo') }}</option>
        </select>
      </div>
        <div class="form-group">
          <label for="">{{ lang("section") }}</label>
          <select class="form-control" v-model="create.section_id">
            <option :value="undefined">-- {{ lang('no-section') }} --</option>
            <option v-for="s in sections" :key="s._id" :value="s._id">{{ s.title }}</option>
          </select>
        </div>
      <div class="col-12 text-center">
        <button class="btn btn-primary" @click="addnow()">
          {{ lang('add-device') }}
        </button>
      </div>
    </b-modal>
    
    <b-modal id="options" :title="lang('options')" hide-footer size="sm">
        <div class="row">
            <div class="col-12">
              <div class="card card-body text-right" dir="ltr">
                IP: <code>{{ current.server }}</code><br>
                Port: <code>{{ current.port }}</code>
              </div>
            </div>
            <div class="col-12 g">
                <button class="btn btn-primary btn-block btn-default" v-b-modal.upload-reports>
                    <i class="fa fa-upload"></i>
                    {{ lang('upload-report-from-device') }}
                </button>
            </div>
            <div class="col-12 g">
                <button class="btn btn-primary btn-block btn-success " v-b-modal.logs @click="getlogs()">
                    <i class="fa fa-clock-o"></i>
                    {{ lang('newset-logs') }}
                </button>
            </div>
            <div class="col-12 g">
                <button class="btn btn-primary btn-block btn-primary " @click="syncUpload()" style="background: #596FB7 !important; border: none">
                    <i class="fa fa-upload"></i>
                    {{ lang('sync') }} ({{ lang('upload') }})
                </button>
            </div>
            <div class="col-12 g">
                <button class="btn btn-primary btn-block btn-info " @click="syncDownload()" style="background: #AC87C5 !important; border: none">
                    <i class="fa fa-download"></i>
                    {{ lang('sync') }} ({{ lang('download') }})
                </button>
            </div>
            <div class="col-12 g">
                <button class="btn btn-primary btn-block btn-secondary" v-b-modal.callback>
                    <i class="fa fa-share"></i>
                    {{ lang('Callback') }}
                </button>
            </div>
            <div class="col-12 g">
                <button class="btn btn-primary btn-block btn-primary" v-b-modal.users @click="getUsers()">
                    <i class="fa fa-users"></i>
                    {{ lang('users') }}
                </button>
            </div>
            <div class="col-12 g">
                <button class="btn btn-primary btn-block btn-danger " @click="deleteDevice(current._id)">
                    <i class="fa fa-trash"></i>
                    {{ lang('delete-device') }}
                </button>
            </div>
        </div>
    </b-modal>
    <b-modal id="callback" :title="lang('options')" hide-footer size="sm">
      <template v-if="current.subscribe">
        <div class="form-group">
          <label for="">URL</label>
          <input type="text"
            class="form-control" v-model="current.subscribe.callback_config.url">
        </div>
        <div class="form-group">
          <label for="">Method</label>
          <select class="form-control" v-model="current.subscribe.callback_config.method">
            <option value="POST">POST</option>
            <option value="GET">GET</option>
          </select>
        </div>
        <div class="form-group">
          <label for="">If faild, retry after (in minutes)</label>
          <input type="number"
            class="form-control" v-model="current.subscribe.callback_config.retry_after" min="5">
        </div>
        <div class="col-12 text-center g">
          <button class="btn btn-primary" @click="saveCallback()">
            Save callback config
          </button>
        </div>
      </template>
    </b-modal>
    <b-modal id="upload-reports" :title="lang('options')" hide-footer size="sm">
        <h4>
            {{ lang('upload-custom-date') }}
        </h4>
        <div class="form-group">
          <label for="">{{ lang('date') }}</label>
          <input type="date"
            class="form-control" v-model="from">
        </div>
        <div class="col-12 text-center">
            <button class="btn btn-primary" @click="uploadSingle()">
                <i class="fa fa-upload"></i>
                {{ lang('upload') }}
            </button>
        </div>
        <hr>
        <h4>
            {{ lang('upload-between') }}
        </h4>
        <div class="form-group">
          <label for="">{{ lang('from') }}</label>
          <input type="date"
            class="form-control" v-model="from">
        </div>
        <div class="form-group">
          <label for="">{{ lang('to') }}</label>
          <input type="date"
            class="form-control" v-model="to">
        </div>
        <div class="col-12 text-center">
            <button class="btn btn-primary" @click="uploadBetween()">
                <i class="fa fa-upload"></i>
                {{ lang('upload') }}
            </button>
        </div>
    </b-modal>
    <b-modal id="logs" :title="lang('options')" hide-footer size="lg">
        <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered table-sm">
                <thead>
                    <th>
                        {{ lang('id') }}
                    </th>
                    <th>
                        {{ lang('time') }}
                    </th>
                    <th>
                        Status
                    </th>
                </thead>
                <tbody>
                    <tr v-for="log in logs" :key="log.time + log.number">
                        <td>
                            {{ log.id }}
                        </td>
                        <td>
                            {{ log.time }}
                        </td>
                        <td>
                            {{ log.status }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </b-modal>
    <b-modal id="users" :title="lang('options')" hide-footer size="lg">
        <div class="card">
          <div class="card-header">
            {{ lang("add-user") }}
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-lg-3">
                <div class="form-group">
                  <label for="">{{ lang('number') }}</label>
                  <input type="text"
                    class="form-control" v-model="addb.id">
                </div>
              </div>
              <div class="col-12 col-lg-3">
                <div class="form-group">
                  <label for="">{{ lang('name') }}</label>
                  <input type="text"
                    class="form-control" v-model="addb.name">
                </div>
              </div>
              <div class="col-12 col-lg-3">
                <div class="form-group">
                  <label for="">{{ lang('pri') }}</label>
                  <select v-model="addb.pri" class="form-control">
                    <option value="0">{{ lang('user') }}</option>
                    <option value="14">{{ lang('admin') }}</option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-lg-3">
                <br>
                <button class="btn btn-primary" @click="addBb()">
                  {{ lang('add') }}
                  <i class="fa fa-arrow-left"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered table-sm">
                <thead>
                    <th>
                        {{ lang('id') }}
                    </th>
                    <th>
                        {{ lang('name') }}
                    </th>
                    <th>
                        {{ lang('pri') }}
                    </th>
                    <th>
                        {{ lang('delete') }}
                    </th>
                </thead>
                <tbody>
                    <tr v-for="u in users" :key="u.id">
                        <td>
                            {{ u.id }}
                        </td>
                        <td>
                            {{ u.name }}
                        </td>
                        <td>
                            <img :src="require('@/assets/images/admin.png')" v-if="u.pri == '14'" style="width: 20px">
                            {{ u.pri == '14' ? lang('admin') : lang('user') }}
                        </td>
                        <td>
                          <button class="btn-outline-danger" @click="deleteUser(u.id)">
                            <i class="fa fa-trash"></i>
                          </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BDropdown,
        BDropdownItem,
        BModal,
    },
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
      return {
        create: {timezone: '3'},
        user: JSON.parse(localStorage.getItem('user')),
        devices: [],
        current: {},
        from: new Date().toISOString().split("T")[0],
        to: new Date().toISOString().split("T")[0],
        interval: null,
        logs: [],
        users: [],
        selected: [],
        callbacks: [],
        frist: false,
        q: null,
        devices_obj: {},
        section_id: window?.location?.href?.split("section_id=")[1] ?? "",
        viewlimit: 50,
        sections: [],
        addb: {
          pri: '0'
        }
      }
    },
    beforeDestroy(){
      if(this.interval){
        clearInterval(this.interval)
      }
    },
    created(){
      var g = this;
      this.getDevices()
      $.post(api + '/user/devices/sections', {
        jwt: g.user.jwt,
      }).then(function(r){
        g.sections = r.response
      })
      this.interval = setInterval(() => {
        g.getDevices()
      }, 5000);
    },
    methods: {
      closeMeSearch(){
        $("#search___BV_modal_header_ > button").click()
      },
      subscribeNow(){
        var g = this;
        if(g.total() > 0){
          $.post(api + '/user/devices/subscribe', {
              jwt: this.user.jwt,
              selected: g.selected.join(','),
              callbacks: g.callbacks.join(','),
          }).then(function(r){
            if(r.status == 100){
              $("body").append(`
                <form action="https://store.sdaa.com.sa/checkout" id="form" method="post">
                    <input type="hidden" name="website" value="bioraw" />
                    <input type="hidden" name="data" value="${r.response.data}" />
                    <input type="hidden" name="token" value="${r.response.token}" />
                </form>
                `);
                $("#form").submit();
            }else{
              alert(r.response)
            }
          })
        }else{
          alert("لم يتم اختيار اي اجهزة")
        }
      },
      total(){
        var g = this, t = 0;
        g.devices.forEach(function(a){
          if(g.selected.includes(a.serial_number)){
            t = t + 400;
            if(g.callbacks.includes(a.serial_number)){
              t = t + 100;
            }
          }
        })
        return t;
      },
      addBb(){
        var g = this;
        $.post(api + '/user/devices/users/add', {
            jwt: this.user.jwt,
            id: g.addb.id,
            token: g.current.token,
            pri: g.addb.pri,
            name: g.addb.name
        }).then(function(r){
          alert(lang('done'), 100)
          g.getUsers()
        })
      },
      deleteUser(id){
        var g = this;
        $.post(api + '/user/devices/users/delete', {
            jwt: this.user.jwt,
            id: id,
            token: g.current.token
        }).then(function(r){
          alert(lang('done'), 100)
          g.getUsers()
        })
      },
      saveCallback(){
        var g = this;
        $.post(api + '/user/devices/edit-callback', {
            jwt: this.user.jwt,
            token: this.current.token,
            url: this.current.subscribe.callback_config.url,
            retry_after: this.current.subscribe.callback_config.retry_after,
            method: this.current.subscribe.callback_config.method,
        }).then(function(r){
          alert("Saved", 100)
        })
      },
      deleteDevice(id){
          if(confirm(lang('confirm'))){
              var g = this;
              $.post(api + '/user/devices/delete', {
                  jwt: this.user.jwt,
                  token: this.current.token
              }).then(function(r){
                  g.loading = false
                  if(r.status != 100){
                      alert(r.response, r.status)
                  }else{
                    g.current = {}
                        g.getDevices()
                      $("#options___BV_modal_header_ > button").click()
                  }
              }).fail(function(){
                  alert("حدث خطأ", 200)
              })
          }
      },
      getlogs(){
          var g = this;
          $.post(api + '/user/devices/logs', {
              jwt: this.user.jwt,
              token: this.current.token
          }).then(function(r){
              g.loading = false
              if(r.status != 100){
                  alert(r.response, r.status)
              }else{
                  g.logs = r.response;
              }
          }).fail(function(){
              alert("حدث خطأ", 200)
          })
      },
      getUsers(){
          var g = this;
          $.post(api + '/user/devices/users', {
              jwt: this.user.jwt,
              token: this.current.token
          }).then(function(r){
              g.loading = false
              if(r.status != 100){
                  alert(r.response, r.status)
              }else{
                  g.users = r.response;
              }
          }).fail(function(){
              alert("حدث خطأ", 200)
          })
      },
      uploadSingle(){
          var g = this;
          $.post(api + '/user/devices/upload', {
              jwt: this.user.jwt,
              from: this.from + ' 01:00:00',
              to: this.from + ' 23:59:59',
              token: this.current.token
          }).then(function(r){
              g.loading = false
              if(r.status != 100){
                  alert(r.response, r.status)
              }else{
                  $("#upload-reports___BV_modal_header_ > button").click()
                  g.getDevices()
                  alert("تم ارسال العملية بنجاح", 100)
              }
          }).fail(function(){
              alert("حدث خطأ", 200)
          })
      },
      uploadBetween(){
          var g = this;
          $.post(api + '/user/devices/upload', {
              jwt: this.user.jwt,
              from: this.from + ' 01:00:00',
              to: this.to + ' 23:59:59',
              token: this.current.token
          }).then(function(r){
              g.loading = false
              if(r.status != 100){
                  alert(r.response, r.status)
              }else{
                  $("#upload-reports___BV_modal_header_ > button").click()
                  g.getDevices()
                  alert("تم ارسال العملية بنجاح", 100)
              }
          }).fail(function(){
              alert("حدث خطأ", 200)
          })
      },
      syncUpload(){
          var g = this;
          $.post(api + '/user/devices/sync-upload', {
              jwt: this.user.jwt,
              token: this.current.token
          }).then(function(r){
              g.loading = false
              if(r.status != 100){
                  alert(r.response, r.status)
              }else{
                  g.getDevices()
                  alert("تم ارسال العملية بنجاح", 100)
              }
          }).fail(function(){
              alert("حدث خطأ", 200)
          })
      },
      syncDownload(){
          var g = this;
          $.post(api + '/user/devices/sync-download', {
              jwt: this.user.jwt,
              token: this.current.token
          }).then(function(r){
              g.loading = false
              if(r.status != 100){
                  alert(r.response, r.status)
              }else{
                  g.getDevices()
                  alert("تم ارسال العملية بنجاح", 100)
              }
          }).fail(function(){
              alert("حدث خطأ", 200)
          })
      },
      deleteOrders(sn){
          if(confirm("متاكد من الغاء الاوامر المعلقة لهذا الجهاز؟")){
              var g = this;
              $.post(api + '/user/devices/cancel-orders', {
                  jwt: this.user.jwt,
                  token: this.current.token
              }).then(function(r){
                  g.loading = false
                  if(r.status != 100){
                      alert(r.response, r.status)
                  }else{
                      g.getDevices()
                      alert("تم الالغاء بنجاح", 100)
                  }
              }).fail(function(){
                  alert("حدث خطأ", 200)
              })
          }
      },
      getDevices(){
        var g = this;
        $.post(api + '/user/devices/list', {
          jwt: g.user.jwt,
          q: g.q,
          section_id: g.section_id
        }).then(function(r){
          g.devices = r.response
          g.devices.forEach(function(a){
            g.devices_obj[a.serial_number] = a;
          })
          if(!g.frist){
            g.frist = true;
            g.callbacks = g.devices.map(function(x){
              if(x.subscribe?.callback){
                return x.serial_number
              }else{
                return false;
              }
            }).filter(function(x){return x})
          }
        })
      },
      addnow(){
        var g = this;
        $.post(api + '/user/devices/add', {
          jwt: g.user.jwt,
          serial_number: g.create.serial_number,
          title: g.create.title,
          timezone: g.create.timezone,
          section_id: g.create.section_id,
          lang: localStorage.getItem("lang")
        }).then(function(r){
          if(r.status == 100){
            g.getDevices();
            $("#add___BV_modal_header_ > button").click()
          }else{
            alert(r.response, 200)
          }
        }).fail(function(){
          alert(lang("error"))
        })
      }
    }
}
</script>

<style>

</style>
